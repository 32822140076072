/* https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-state-slice */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const headers = {
  'Content-Type': 'application/json',
};

export const getParks = createAsyncThunk(
  'users/getParks',
  async (_, thunkAPI) => {
    let value;

    try {
      const response = await fetch(
        '/api/parks', { method: 'GET', headers },
      );

      if (response.ok) {
        value = (await response.json()).parks;
      } else {
        const errorMessage = (await response.json()).msg;

        value = thunkAPI.rejectWithValue(errorMessage);
      }
    } catch (e) {
      value = thunkAPI.rejectWithValue(e.toString());
    }

    return value;
  },
);

export const loginUser = createAsyncThunk(
  'users/login',
  async (credentials, thunkAPI) => {
    let value;

    try {
      const response = await fetch(
        '/api/auth/login',
        { method: 'POST', headers, body: JSON.stringify(credentials) },
      );

      if (response.ok) {
        value = (await response.json()).user;

        await thunkAPI.dispatch(getParks());
      } else {
        value = thunkAPI.rejectWithValue(response.statusText);
      }
    } catch (e) {
      value = thunkAPI.rejectWithValue(e.toString());
    }

    return value;
  },
);

export const logoutUser = createAsyncThunk(
  'users/logout',
  async (_, thunkAPI) => {
    let value;

    try {
      const response = await fetch(
        '/api/auth/logout', { method: 'POST', headers },
      );

      if (response.ok) {
        value = response.statusText;
      } else {
        value = thunkAPI.rejectWithValue(response.statusText);
      }
    } catch (e) {
      value = thunkAPI.rejectWithValue(e.toString());
    }

    return value;
  },
);

export const getCurrentUser = createAsyncThunk(
  'users/getCurrentUser',
  async (_, thunkAPI) => {
    let value;

    try {
      const response = await fetch(
        '/api/users/current-user', { method: 'GET', headers },
      );

      if (response.ok) {
        value = (await response.json()).user;

        await thunkAPI.dispatch(getParks());
      } else {
        value = thunkAPI.rejectWithValue(response.statusText);
      }
    } catch (e) {
      value = thunkAPI.rejectWithValue(e.toString());
    }

    return value;
  },
);

export const requestResetPasswordLink = createAsyncThunk(
  'users/requestResetPasswordLink',
  async ({ email }, thunkAPI) => {
    let value;

    try {
      const response = await fetch(
        `/api/password-reset-email/${email}`, { method: 'POST', headers },
      );

      if (response.ok) {
        value = response.statusText;
      } else {
        const thing = await response.json();

        value = thunkAPI.rejectWithValue(thing);
      }
    } catch (e) {
      value = thunkAPI.rejectWithValue(e.toString());
    }

    return value;
  },
);

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (
    {
      userId,
      token,
      password,
      passwordConfirmation,
    },
    thunkAPI,
  ) => {
    let value;

    try {
      const response = await fetch(
        `/api/password-reset/${userId}/${token}`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ password, passwordConfirmation }),
        },
      );

      if (response.ok) {
        value = response.statusText;
      } else {
        const thing = await response.json();

        value = thunkAPI.rejectWithValue(thing);
      }
    } catch (e) {
      value = thunkAPI.rejectWithValue(e.toString());
    }

    return value;
  },
);

const initialState = {
  businessid: '',
  created: '',
  id: '',
  email: '',
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: '',
  parks: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState: (state) => {
      state.businessid = '';
      state.id = '';
      state.created = '';
      state.email = '';
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.error = '';
      state.parks = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.businessid = payload.businessid;
      state.id = payload.id;
      state.created = payload.created;
      state.email = payload.email;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = '';
      state.isError = false;
    });

    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = payload;
      state.businessid = '';
      state.id = '';
      state.created = '';
      state.email = '';
    });

    builder.addCase(logoutUser.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(logoutUser.fulfilled, (state) => {
      state.businessid = '';
      state.id = '';
      state.created = '';
      state.email = '';
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    });

    builder.addCase(logoutUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    });

    builder.addCase(getCurrentUser.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.businessid = payload.businessid;
      state.id = payload.id;
      state.created = payload.created;
      state.email = payload.email;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = '';
    });

    builder.addCase(getCurrentUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.id = '';
      state.email = '';
      state.created = '';
      state.businessid = '';
      state.isSuccess = false;
      state.isError = false;
      state.error = payload;
    });

    builder.addCase(getParks.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(getParks.fulfilled, (state, { payload }) => {
      state.parks = payload;
      state.error = '';
    });

    builder.addCase(getParks.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.id = '';
      state.email = '';
      state.created = '';
      state.businessid = '';
      state.isSuccess = false;
      state.isError = true;
      state.error = payload;
    });

    builder.addCase(requestResetPasswordLink.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(requestResetPasswordLink.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.id = payload;
      state.isSuccess = true;
      state.error = '';
      state.isError = false;
    });

    builder.addCase(requestResetPasswordLink.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = payload;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.id = payload;
      state.isSuccess = true;
      state.error = '';
      state.isError = false;
    });

    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = payload;
    });
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;

export default userSlice.reducer;
