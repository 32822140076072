import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser, userSelector } from 'features/userSlice';
import { FullPageSpinner } from 'components/lib';

const AuthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ './authenticated-app'));
const UnauthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ './unauthenticated-app'));

function App() {
  const dispatch = useDispatch();
  const {
    email, isFetching, isSuccess,
  } = useSelector(userSelector);

  React.useEffect(() => {
    if (!email) {
      dispatch(getCurrentUser());
    }
  }, [email, dispatch]);

  let view = <FullPageSpinner />;

  if (!isFetching && isSuccess && email) {
    view = <AuthenticatedApp />;
  }

  if (!isFetching && !isSuccess && !email) {
    view = <UnauthenticatedApp />;
  }

  if (!isFetching && isSuccess && !email) {
    view = <UnauthenticatedApp />;
  }

  return (
    <React.Suspense fallback={<></>}>
      {view}
    </React.Suspense>
  );
}

export default App;
